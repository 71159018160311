// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

$color-primary: #7b40E9;
$color-seconday: #238bd6;

/** Ionic CSS Variables **/
:root {
  --ion-background-color: $color-primary;

  --ion-color-primary: #7b40E9;
  --ion-color-primary-rgb: 123,64,233;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #6c38cd;
  --ion-color-primary-tint: #8853eb;

  --ion-color-secondary: #238bd6;
  --ion-color-secondary-rgb: 35,139,214;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #1f7abc;
  --ion-color-secondary-tint: #3997da;

  --ion-color-tertiary: #e54990;
  --ion-color-tertiary-rgb: 229,73,144;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #ca407f;
  --ion-color-tertiary-tint: #e85b9b;

  --ion-color-success: #07a65b;
  --ion-color-success-rgb: 7,166,91;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #069250;
  --ion-color-success-tint: #20af6b;

  --ion-color-warning: #fccf2b;
  --ion-color-warning-rgb: 252,207,43;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #deb626;
  --ion-color-warning-tint: #fcd440;

  --ion-color-danger: #eb3144;
  --ion-color-danger-rgb: 235,49,68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf2b3c;
  --ion-color-danger-tint: #ed4657;

  --ion-color-medium: #667185;
  --ion-color-medium-rgb: 102,113,133;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #5a6375;
  --ion-color-medium-tint: #757f91;

  --ion-color-light: #cdd0d7;
  --ion-color-light-rgb: 205,208,215;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #b4b7bd;
  --ion-color-light-tint: #d2d5db;

    /** gray **/
    --ion-color-gray: #7f7f7f;
    --ion-color-gray-rgb: 127,127,127;
    --ion-color-gray-contrast: #ffffff;
    --ion-color-gray-contrast-rgb: 255,255,255;
    --ion-color-gray-shade: #707070;
    --ion-color-gray-tint: #8c8c8c;
  
  /** white **/
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    /** dark-gray **/
    --ion-color-dark-gray: #A0A3BD;
    --ion-color-dark-gray-rgb: 160,163,189;
    --ion-color-dark-gray-contrast: #000000;
    --ion-color-dark-gray-contrast-rgb: 0,0,0;
    --ion-color-dark-gray-shade: #8d8fa6;
    --ion-color-dark-gray-tint: #aaacc4;

    /** light-gray **/

    --ion-color-light-gray: #EFF0F6;
    --ion-color-light-gray-rgb: 247,247,252;
    --ion-color-light-gray-contrast: #000000;
    --ion-color-light-gray-contrast-rgb: 0,0,0;
    --ion-color-light-gray-shade: #d9d9de;
    --ion-color-light-gray-tint: #f8f8fc;
}

.ion-color-light-gray {
	--ion-color-base: var(--ion-color-light-gray);
	--ion-color-base-rgb: var(--ion-color-light-gray-rgb);
	--ion-color-contrast: var(--ion-color-light-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-gray-shade);
	--ion-color-tint: var(--ion-color-light-gray-tint);
}
.ion-color-dark-gray {
	--ion-color-base: var(--ion-color-dark-gray);
	--ion-color-base-rgb: var(--ion-color-dark-gray-rgb);
	--ion-color-contrast: var(--ion-color-dark-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-gray-shade);
	--ion-color-tint: var(--ion-color-dark-gray-tint);
}
.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}
$theme: md;
//@media (prefers-color-scheme: dark) {
//  :root {
//    --ion-background-color: black;
//    --ion-text-color: #ffffff;
//  }
//  ion-tab-bar{
//    --color: white;
//  }
//
//}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #428cff;
//    --ion-color-primary-rgb: 66,140,255;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
