/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "assets/fonts/hk-groteks/style.css";
@import "theme/variables.scss";

* {
  font-family: "HK Grotesk", sans-serif;
  --ion-font-family: "HK Grotesk";
  // font-weight: 600;
}

//body{
//  --background: $color-primary;
//  background-color: $color-primary;
//  color: white;
//}
//*{
//  color:white
//}
iframe#caleida-chatbot-iframe_f084077c-5c7f-4077-ae4a-3c96160956ae {
  position: relative;
  z-index: 50000000;
}

ion-footer {
  width: auto;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0 30px calc(0.5rem + env(safe-area-inset-bottom));
  ion-button {
    flex: 0 0 100%;
    margin-bottom: 6px;
    font-size: 1rem;
    text-decoration: none;
    text-transform: none;
  }
}

.spinner-bubbles {
  --color: #f7f6fa ;
}
// ion-button {
//   --border-style: 3px solid white !important;
//   --border-radius: 5px;
//   --box-shadow: 0px 0px 2px 2px white;
//   &.small {P
//     font-size: 0.8em !important;
//   }

//   &.white {
//     --background: white;
//     --box-shadow: 0px 0px 2px 2px #7b40e9ff;
//     --color: #7b40e9ff;
//     &:hover {
//       --background: #7b40e9ff;
//       --color: white;
//     }
//   }
//   &.danger {
//     --background: white;
//     --box-shadow: 0px 0px 2px 2px #eb4253;
//     --color: #eb4253;
//     &:hover {
//       --background: #eb4253;
//       --box-shadow: 0px 0px 2px 2px #eb4253;
//       --color: white;
//     }
//   }
// }

.bgPurple {
  --color: white !important;
  color: white !important;
  background-color: $color-primary !important;
  --background: $color-primary !important;

  ion-toolbar {
    --color: white !important;
    color: white !important;
    background-color: $color-primary !important;
    --background: $color-primary !important;
  }

  //*{
  //  --color: white !important;
  //  color:white !important;
  //  background-color: $color-primary !important;
  //  --background: $color-primary !important;
  //}
}

ion-modal {
  background: lightgray !important;
  --background: lightgray !important;

  ion-toolbar {
    background: lightgray !important;
    --background: lightgray !important;
  }

  ion-content {
    background: lightgray !important;
    --background: lightgray !important;

    ion-item {
      background: lightgray !important;
      --background: lightgray !important;
    }
  }
}

.bgGrey {
  background-color: lightgray !important;
  --background: lightgray !important;
}

ion-header {
  // padding-top: calc(0.5rem + env(safe-area-inset-top));

  ion-toolbar {
    button {
      background-color: red;

      &.none {
        opacity: 0;
      }
    }

    display: flex;
  }

  ion-icon {
    font-size: 2em;
  }
  &.bgPurple {
    ion-icon {
      color: white;
    }
  }

  //h1{
  //  margin-top: 0;
  //}
  ion-img {
    width: 24px !important;
    height: auto;
    margin: 0 auto;
  }

  //ion-title{
  //  width: 100%;
  //  position: absolute;
  //  //left: 0;
  //  //height: 0;
  //}
}

.showPasswordButton {
  margin-top: 20px;
  border-radius: 16px;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
  color: white;
  border: none;
}
.showPasswordButton:active {
  background-color: rgba(var(--ion-color-light-rgb), 0.5);
}

ion-content {
  border: none;
  &.center-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* Works - pass "my-custom-class" in cssClass to increase specificity */
.alert-wrapper {
  background: #e5e5e5;
  --background: #e5e5e5;
}

h1 {
  font-size: 1.8em !important;
  line-height: 1.5em !important;
}

ion-tab-button {
  div.player {
    background-color: $color-primary;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    padding: 13px;
    color: white;
    font-size: 2em;
  }
}

ion-grid.screen-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  ion-col {
    text-align: center;
  }
}

////////////////
///
///
/// Maher started here
///
/// ////////////
.title {
  font-size: 28px;
  font-weight: bold;
}
.whiteBG {
  padding: 0;
  background-color: white;
}
.center-item {
  width: 100%;
  display: flex;
  justify-content: center;
}

ion-title {
  font-weight: 600;
}
