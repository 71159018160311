@font-face {
  font-family: "HK Grotesk SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("HK Grotesk SemiBold"),
    url("HKGrotesk-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "HK Grotesk SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("HK Grotesk SemiBold Italic"),
    url("HKGrotesk-SemiBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 900;
  src: local("HKGrotesk-Black"),
    url(".\\HKGrotesk-Black.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 600;
  src: local("HKGrotesk-Bold"), url(".\\HKGrotesk-Bold.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: bold;
  src: local("HKGrotesk-BoldItalic"),
    url(".\\HKGrotesk-BoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: bold;
  src: local("HKGrotesk-BoldLegacy"),
    url(".\\HKGrotesk-BoldLegacy.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: bold;
  src: local("HKGrotesk-BoldLegacyItalic"),
    url(".\\HKGrotesk-BoldLegacyItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 800;
  src: local("HKGrotesk-ExtraBold"),
    url(".\\HKGrotesk-ExtraBold.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-Italic"),
    url(".\\HKGrotesk-Italic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-LegacyItalic"),
    url(".\\HKGrotesk-LegacyItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: normal;
  src: local("HKGrotesk-Light"),
    url(".\\HKGrotesk-Light.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-LightItalic"),
    url(".\\HKGrotesk-LightItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: normal;
  src: local("HKGrotesk-LightLegacy"),
    url(".\\HKGrotesk-LightLegacy.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-LightLegacyItalic"),
    url(".\\HKGrotesk-LightLegacyItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 400;
  src: local("HKGrotesk-Medium"),
    url(".\\HKGrotesk-Medium.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-MediumItalic"),
    url(".\\HKGrotesk-MediumItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: 300;
  src: local("HKGrotesk-MediumLegacy"),
    url(".\\HKGrotesk-MediumLegacy.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: normal;
  src: local("HKGrotesk-MediumLegacyItalic"),
    url(".\\HKGrotesk-MediumLegacyItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: normal;
  src: local("HKGrotesk-Regular"),
    url(".\\HKGrotesk-Regular.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: normal;
  src: local("HKGrotesk-RegularLegacy"),
    url(".\\HKGrotesk-RegularLegacy.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: bold;
  src: local("HKGrotesk-SemiBold"),
    url(".\\HKGrotesk-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: bold;
  src: local("HKGrotesk-SemiBoldItalic"),
    url(".\\HKGrotesk-SemiBoldItalic.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: normal;
  font-weight: bold;
  src: local("HKGrotesk-SemiBoldLegacy"),
    url(".\\HKGrotesk-SemiBoldLegacy.otf") format("opentype");
}
@font-face {
  font-family: "HK Grotesk";
  font-style: italic;
  font-weight: bold;
  src: local("HKGrotesk-SemiBoldLegacyItalic"),
    url(".\\HKGrotesk-SemiBoldLegacyItalic.otf") format("opentype");
}
